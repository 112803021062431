<template>
    <div v-if="loading" class="vehicle-card vehicle-card--loading">
        <div class="vehicle-card__images">
            <VueSkeletonLoader width="100%" height="100%" animation="wave" />
        </div>
        <div class="vehicle-card__content">
            <div class="vehicle-card__row">
                <VueSkeletonLoader width="60%" height="15px" animation="wave" />
                <VueSkeletonLoader width="20%" height="15px" animation="wave" />
            </div>
            <div class="vehicle-card__row">
                <VueSkeletonLoader width="45%" height="18px" animation="wave" />
            </div>
            <div class="vehicle-card__row">
                <VueSkeletonLoader width="35%" height="24px" animation="wave" />
            </div>
            <div class="vehicle-card__row">
                <VueSkeletonLoader width="30%" height="26px" animation="wave" />
                <VueSkeletonLoader
                    v-if="showPrice"
                    style="margin-left: auto"
                    width="25%"
                    height="26px"
                    animation="wave"
                />
            </div>
        </div>
    </div>
    <div
        v-else
        class="vehicle-card"
        :class="{
            'vehicle-card--with-insurance': vehicle.hasPlatformInsurance,
            'vehicle-card--tiny': size === 'tiny',
        }"
        @click="handleCardClick"
    >
        <LocalizedLink
            class="vehicle-card__link"
            :to="{ name: 'vehicle', params: { id: vehicle.id }, query: queryParams }"
            :target="vehicleLinkTarget"
        >
            <client-only>
                <VehicleIconBar
                    :show-top-owner-badge="vehicle.owner && !!vehicle.owner.topOwner"
                    :vehicle="vehicle"
                    :index-in-list="indexInList"
                />
            </client-only>

            <div v-if="vehicle.images" class="vehicle-card__images">
                <VehicleImageCarousel
                    :image-count="imageCount"
                    :images="vehicle.images"
                    image-size="medium"
                    :lazy-load-near-viewport="lazyLoadCardImages"
                    :is-near-viewport-fold="isNearViewportFold"
                />
            </div>

            <div class="vehicle-card__content">
                <div class="vehicle-card__row">
                    <VehicleLocation :vehicle="vehicle" />
                    <span v-if="isNewVehicle" class="vehicle-card__new">{{ $t('vehicle_card.new_vehicle') }}</span>
                    <VehicleRating v-else :value="vehicle.averageRating" :num-of-reviews="vehicle.reviewCount" />
                </div>

                <div class="vehicle-card__row">
                    <span class="vehicle-card__name">{{ vehicleName }}</span>
                </div>

                <div class="vehicle-card__row">
                    <div class="vehicle-card__meta">
                        <div v-if="vehicle.sitsNumber" class="vehicle-card__meta-value">
                            <svg-icon name="seat_belt" />
                            {{ vehicle.sitsNumber }}
                        </div>
                        <div class="vehicle-card__meta-value">
                            <svg-icon name="hotel" />
                            {{ vehicle.sleepsNumber }}
                        </div>
                        <div
                            v-if="showMatchedDates && flexDatesEnabled && matchedDates"
                            class="vehicle-card__meta-value"
                            :class="{ 'is-false-positive': !matchedOnSearchedDates }"
                        >
                            <svg-icon name="datepicker" />
                            <span>
                                <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
                                {{ matchedDates.from | dateFormat('d MMM') }} –
                                {{ matchedDates.to | dateFormat('d MMM') }}
                            </span>
                        </div>
                    </div>
                </div>

                <div
                    v-if="isInstantBooking || showPrice"
                    class="vehicle-card__instant"
                    :style="showPrice ? 'min-height: 32px' : ''"
                >
                    <template v-if="isInstantBooking">
                        <svg-icon name="instant" />
                        <span>{{ $t('vehicle_card.instant') }}</span>
                    </template>
                </div>

                <div v-if="showPrice" class="vehicle-card__price">
                    <template v-if="numberOfNights > 30">
                        <div>
                            <span>{{ $t('common.from') }}</span>
                            <strong>{{ totalPriceFormatted }}</strong>
                        </div>
                        <span>{{ $t('common.per_month') }}</span>
                    </template>
                    <strong v-else-if="selectedDates.from">
                        {{ totalPriceFormatted }}
                    </strong>
                    <template v-else>
                        <div>
                            <span>{{ $t('common.from') }}</span>
                            <strong>{{ totalPriceFormatted }}</strong>
                        </div>
                        <span>{{ $t('common.per_night') }}</span>
                    </template>
                </div>
            </div>
        </LocalizedLink>
        <div v-if="isAdmin" class="vehicle-card__admin-link">
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text prettier/prettier -->
            <a :href="adminUrl" target="_blank">Visa i admin<svg-icon name="keyboard_arrow_right" /></a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import parseISO from 'date-fns/parseISO';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import formatISO from 'date-fns/formatISO';
import differenceInDays from 'date-fns/differenceInDays';
import { getVehicleFieldFormattedValue, getVehicleFieldValue } from '~/config/vehicleFields';
import { getFilterValue, buildNewQuery } from '~/helpers/queryParser';
import { dateModeValues } from '~/config/filters';
import { AVAILABLE_BETWEEN } from '~/config/filterKeys';

import VehicleImageCarousel from '~/components/vehicles/VehicleImageCarousel.vue';
import VehicleRating from '~/components/VehicleRating';
import VehicleIconBar from '~/components/vehicles/VehicleIconBar.vue';
import VehicleLocation from '~/components/vehicles/VehicleLocation';

export default {
    components: {
        VehicleLocation,
        VehicleRating,
        VehicleImageCarousel,
        VehicleIconBar,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        vehicle: {
            type: Object,
            default: () => {},
        },
        showMatchedDates: {
            type: Boolean,
            default: false,
        },
        showPrice: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: null,
        },
        imageCount: {
            type: Number,
            default: -1,
        },
        indexInList: {
            type: Number,
            default: null,
        },
        origin: {
            type: String,
            default: '',
        },
        isNearViewportFold: {
            type: Boolean,
            default: false,
        },
        lazyLoadCardImages: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['isAdmin', 'vehicleLinkTarget']),
        selectedDates() {
            return getFilterValue(this.$route.query, AVAILABLE_BETWEEN);
        },
        flexDatesEnabled() {
            return this.selectedDates.mode === dateModeValues.FLEX;
        },
        isInstantBooking() {
            return this.vehicle?.bookingMethod === 'instant';
        },
        isNewVehicle() {
            return (
                (!this.vehicle.reviewCount || this.vehicle.reviewCount === 0) &&
                this.vehicle.firstActivationAt &&
                differenceInCalendarMonths(
                    parseISO(formatISO(new Date(), { representation: 'date' })),
                    parseISO(this.vehicle.firstActivationAt)
                ) < 6
            );
        },
        vehicleName() {
            return this.$t('vehicle_card.name', {
                vehicleBrand: getVehicleFieldValue(this.vehicle, 'brand'),
                type: getVehicleFieldFormattedValue(this.vehicle, 'subType'),
            });
        },
        matchedDates() {
            if (!this.selectedDates.from || !this.selectedDates.to) {
                return null;
            }

            return this.vehicle.matchedDates;
        },
        matchedOnSearchedDates() {
            if (!this.matchedDates) {
                return false;
            }

            return this.matchedDates.from === this.selectedDates.from && this.matchedDates.to === this.selectedDates.to;
        },
        totalPriceFormatted() {
            return this.$root.$options.filters.formatAsPrice(
                this.$utils.vehicleCardPrice(this.vehicle, this.selectedDates),
                this.vehicle.currency,
                0
            );
        },
        adminUrl() {
            const baseUrl = `${this.$config.mcBackendUrl}/admin`;

            return `${baseUrl}/resources/assets/${this.vehicle.id}`;
        },
        numberOfNights() {
            if (this.selectedDates.from && this.selectedDates.to) {
                return differenceInDays(parseISO(this.selectedDates.to), parseISO(this.selectedDates.from));
            }

            return 0;
        },
        queryParams() {
            if (!this.selectedDates && !this.matchedDates) {
                return {};
            }

            if (this.matchedDates) {
                return buildNewQuery({ [AVAILABLE_BETWEEN]: this.matchedDates });
            }

            return buildNewQuery({ [AVAILABLE_BETWEEN]: this.selectedDates });
        },
    },
    methods: {
        handleCardClick() {
            this.$nextTick(() => {
                localStorage.setItem(
                    'mc-selected-vehicle-origin',
                    JSON.stringify({ list: this.origin, index: this.indexInList + 1, id: this.vehicle.id })
                );
                this.dispatchSelectItemEvent();
            });
        },
        dispatchSelectItemEvent() {
            const { price, dateFormat } = this.$root.$options.filters;
            const { totalPrice = 0, owner } = this.vehicle;

            const start = this.selectedDates.from && dateFormat(this.selectedDates.from, 'dd.MM.');
            const end = this.selectedDates.to && dateFormat(this.selectedDates.to, 'dd.MM.yyyy');

            let pricePerNight;

            if (this.selectedDates.from) {
                const numberOfNights = this.numberOfNights > 30 ? 30 : this.numberOfNights;
                pricePerNight = Math.round(price(totalPrice / numberOfNights));
            } else {
                pricePerNight = Math.round(price(totalPrice / 7));
            }

            let ownerName = '';
            let itemName = '';

            if (owner) {
                ownerName = owner.isOrganizationRepresentative ? '' : ` of ${owner.firstName}`;
                itemName = `${this.vehicle.brand} ${this.vehicle.subType}${ownerName}`;
            }

            if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                    event: 'select_item',
                    ecommerce: {
                        items: [
                            {
                                booking_dates: start ? `${start}-${end}` : null,
                                item_id: this.vehicle.id,
                                item_name: itemName,
                                item_owner_status: owner?.topOwner ? 'top owner' : 'regular',
                                item_brand: this.vehicle.brand,
                                item_category: this.vehicle.type,
                                item_category2: this.vehicle.subType,
                                item_country: this.$t(`localization.countries.${this.vehicle.country}`, 'en'),
                                price: pricePerNight,
                                item_cancellation_policy: this.vehicle.cancellationPolicy,
                                item_booking_method: this.vehicle.bookingMethod,
                                item_number_of_reviews: this.vehicle.reviewCount,
                                item_rating: this.vehicle.averageRating || 0,
                                locale: this.$i18n.locale,
                                item_list_name: this.origin,
                                index: this.indexInList + 1,
                            },
                        ],
                    },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.vehicle-card {
    @extend %card;
    display: inline-block;
    min-width: 230px;
    width: 100%;
    position: relative;
    overflow: hidden;
    color: $primary-text-color;
    @include font-size(14px);
    font-family: $base-font;

    &--loading {
        box-sizing: border-box;
        .vehicle-card__row:not(:last-child) {
            padding-bottom: 5px;
        }
    }

    &__link {
        color: $primary-text-color;
        text-decoration: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.05);
    }

    &__images {
        height: 240px;
        overflow: hidden; // The controls overlay was a bit taller than the image, this hides that
    }

    &--tiny {
        .vehicle-card__images {
            height: 180px;
        }
    }

    &__content {
        padding: 12px;
        text-align: left;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .loader {
            border-radius: 4px !important;
        }
    }

    &__price {
        position: absolute;
        bottom: 13px;
        right: 12px;
        text-align: right;
        line-height: 20px;

        strong {
            @include font-size(16px);
        }
    }

    &__meta {
        display: flex;
        align-items: center;
        align-self: flex-start;
        white-space: nowrap;
        color: $darkest-gray;

        .svg-icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
            margin-bottom: 2px;
        }

        .is-false-positive .svg-icon {
            color: $warning;
        }
    }

    &__name {
        @include font-size(16px);
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__new {
        display: inline-flex;
        align-items: center;
        min-height: 20px;
        text-align: right;
        padding-left: 14px;
        white-space: nowrap;
    }

    .vehicle-rating {
        padding-left: 14px;
        white-space: nowrap;
    }

    &__instant {
        font-weight: 600;
        display: flex;
        align-items: center;
        .svg-icon {
            height: 32px;
            width: 32px;
            margin: 0 8px 0 -4px;
        }
    }

    &__meta-value {
        margin-right: 4px;
        display: flex;
        align-items: center;

        &:not(:last-child) {
            border-right: 1px solid $darkest-gray;
            padding-right: 4px;
        }
    }

    &__admin-link {
        background: $gray-light;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 5px;
        text-align: center;
    }
}
</style>
