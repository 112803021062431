import filtersConfig, { filterTypeValues, sortValues, dateModeValues } from '~/config/filters';
import {
    filterKeys,
    LOCATION,
    RADIUS,
    SORT,
    AVAILABLE_BETWEEN,
    IS_FEATURED,
    UNKNOWN_QUERY_PARAM,
    PRICE_RANGE,
    SEARCH,
    COUNTRY,
} from '~/config/filterKeys';
import { swissAssetCountryCodes } from '~/config/countryCodeLists';

/**
 * Get the filter value from the query (if it is not there it gets the default value)
 *
 * @param {Object} query
 * @param {string} key
 *
 * @returns {string | boolean | null}
 */
export const getFilterValue = (query, key) => {
    if (!Object.prototype.hasOwnProperty.call(filtersConfig, key)) {
        console.error(`Filter key "${key}" does not exist in the filters configuration file`);

        return null;
    }

    const value = query[key];

    if (value === undefined) {
        return filtersConfig[key].default;
    }

    if (filtersConfig[key].requiresCustomQueryHandling) {
        return _extractSpecialQueryParam(key, value);
    }

    return value;
};

/**
 * Get the default value of the filter key from filters.js
 *
 * @param {string} key
 *
 * @returns {string | boolean | null}
 */
export const getDefaultFilterValue = key => {
    if (!Object.prototype.hasOwnProperty.call(filtersConfig, key)) {
        // Can not throw an error here since we have to support query params that are not in the filters config (otherwise buildQuery does not work with 'all-vehicles')
        return UNKNOWN_QUERY_PARAM;
    }

    if (!Object.prototype.hasOwnProperty.call(filtersConfig[key], 'default')) {
        console.error(`Filter key "${key}" does not have a default value in the filters configuration file`);

        return null;
    }

    return filtersConfig[key].default;
};

/**
 * Check if any filters are active
 * Filter is active if it exists in config and is not the default value, is not hidden type (e.g. is_featured) and is not sort
 * Used to check whether to show the clear filters button
 *
 * @param {Object} query
 *
 * @returns {boolean}
 */
export const anyFiltersActive = query =>
    Object.keys(query).some(key => {
        return (
            Object.prototype.hasOwnProperty.call(filtersConfig, key) &&
            query[key] !== getDefaultFilterValue(key) &&
            filtersConfig[key].type !== filterTypeValues.HIDDEN &&
            filtersConfig[key].type !== filterTypeValues.SORT
        );
    });

/**
 * Get the active additional filters count
 * Filter is active if it exists in config and is not the default value and is additional type
 * Used for the badge on the more filters button
 *
 * @param {Object} query
 *
 * @returns {number}
 */
export const getActiveAdditionalFiltersCount = query => {
    return Object.keys(query).filter(key => {
        return (
            Object.prototype.hasOwnProperty.call(filtersConfig, key) &&
            query[key] !== getDefaultFilterValue(key) &&
            filtersConfig[key].type === filterTypeValues.ADDITIONAL
        );
    }).length;
};

/**
 * Merges new filters with the existing query
 * If the filter value is the same as the default value, it is removed from the query
 *
 * @param {Object} query
 * @param {Object} newFilters
 *
 * @returns {Object}
 */
export const buildQuery = (query, newFilters, params = {}) => {
    const mergedFilters = { ...query };

    Object.keys(newFilters).forEach(key => {
        const defaultValue = getDefaultFilterValue(key);
        let value = newFilters[key];

        if (defaultValue === UNKNOWN_QUERY_PARAM) {
            mergedFilters[key] = value;

            return;
        }

        if (filtersConfig[key]?.requiresCustomQueryHandling) {
            value = _buildSpecialQueryParam(key, value, query);
        }

        if (Array.isArray(value)) {
            value = value.join(',');
        }

        const isDefaultValueObject = typeof defaultValue === 'object' && defaultValue !== null;
        const valueMatchesDefault = isDefaultValueObject
            ? JSON.stringify(value) === JSON.stringify(defaultValue)
            : value === defaultValue;

        if (valueMatchesDefault) {
            delete mergedFilters[key];
        } else {
            mergedFilters[key] = value;
        }

        // Handle sort param
        if (key === LOCATION && value !== defaultValue && !params[LOCATION]) {
            mergedFilters[SORT] = sortValues.POPULARITY;
        }

        if (
            (key === SORT &&
                value === sortValues.DISTANCE &&
                !newFilters[LOCATION] &&
                !mergedFilters[LOCATION] &&
                !params[LOCATION]) ||
            (key === LOCATION && value === defaultValue)
        ) {
            delete mergedFilters[SORT];
        }
        // End handle sort param

        // Reduce radius for Swiss locations
        if (
            !newFilters[RADIUS] &&
            !mergedFilters[RADIUS] &&
            key === COUNTRY &&
            swissAssetCountryCodes.includes(value)
        ) {
            mergedFilters[RADIUS] = 20;
        }
    });

    return mergedFilters;
};

/**
 * Builds a new query with the new filters regardless of the current query
 *
 * @param {Object} newFilters
 *
 * @returns {Object}
 */
export const buildNewQuery = newFilters => buildQuery({}, newFilters);

/**
 * Removes the main filters from the query
 *
 * @param {Object} query
 *
 * @returns {Object}
 */
export const removeMainFilters = query => {
    const newQuery = { ...query };

    Object.keys(newQuery).forEach(key => {
        if (filtersConfig[key]?.type === filterTypeValues.MAIN) {
            delete newQuery[key];
        }
    });

    return newQuery;
};

/**
 * Removes all filters
 *
 * @param {Object} query
 *
 * @returns {Object}
 */
export const removeAllFilters = query => {
    const filters = { ...query };
    const allFiltersKeys = Object.values(filterKeys);

    for (const key in filters) {
        if (allFiltersKeys.includes(key) && key !== filterKeys.SORT) {
            delete filters[key];
        }
    }

    return buildNewQuery(filters);
};

/**
 * Parses the current query and prepares it for the getVehicles API request
 * Removes filters that are the same as the default value and filters that are excludedFromBackendQuery (e.g. radius)
 * Handles special cases where on front we have 2 query params for one filter (e.g. location) and other
 * Returns the parsed query
 *
 * @param {Object} query
 * @param {string} siteCountryCode - The country code of the site (needed for sorting)
 *
 * @returns {Object}
 */
export const prepareQueryForApiRequest = (query, siteCountryCode) => {
    if (typeof query !== 'object' || !query) {
        return {};
    }

    const parsedQuery = {
        filter: {},
    };

    const locationValue = getFilterValue(query, LOCATION);

    // FILTERS
    for (const [key, value] of Object.entries(query)) {
        if (!Object.prototype.hasOwnProperty.call(filtersConfig, key)) {
            continue;
        }

        if (value === getDefaultFilterValue(key) || filtersConfig[key]?.excludedFromBackendQuery) {
            continue;
        }

        if (key === LOCATION) {
            const radiusValue = getFilterValue(query, RADIUS);
            const location = _buildLocationForAPI(value, radiusValue);

            parsedQuery.filter[key] = location;
        } else if (key === SORT) {
            const sortValue = getFilterValue(query, key);
            const isFeatured = getFilterValue(query, IS_FEATURED);
            const sort = _buildSortForAPI(sortValue, locationValue, siteCountryCode, isFeatured);

            parsedQuery[key] = sort;
        } else if (key === SEARCH) {
            const searchValue = getFilterValue(query, key);

            parsedQuery[key] = searchValue;
        } else if (key === AVAILABLE_BETWEEN) {
            const dates = getFilterValue(query, key);

            // Builds dates as a string (from object) if they exist
            if (dates !== getDefaultFilterValue(key)) {
                parsedQuery.filter[key] = _buildDatesQueryParam(dates, query);
            }
        } else {
            parsedQuery.filter[key] = value;
        }
    }

    if (siteCountryCode && locationValue === getDefaultFilterValue(LOCATION)) {
        parsedQuery.country = siteCountryCode;
    }

    if (Object.keys(parsedQuery.filter).length === 0) {
        delete parsedQuery.filter;
    }

    return parsedQuery;
};

/**
 * LOCATION
 * Builds the location query param
 * Should be in format {lat},{lng}
 *
 * @param {Object} locationValue
 */
const _buildLocationQueryParam = locationValue => {
    const defaultLocation = getDefaultFilterValue(LOCATION);

    if (!locationValue) {
        return defaultLocation;
    }

    if (typeof locationValue !== 'object') {
        console.error(`Invalid location format: ${locationValue}`);

        return defaultLocation;
    }

    if (!locationValue.lat || !locationValue.lng) {
        return defaultLocation;
    }

    return `${locationValue.lat},${locationValue.lng}`;
};

/**
 * LOCATION
 * Extracts the location object from the location query param
 * Should be in format {lat},{lng},{radius}
 * Returns an object { lat: number, lng: number, radius: number }
 *
 * @param {string} locationValue
 *
 * @returns {Object}
 */
export const _extractLocationFromQueryParam = locationValue => {
    const defaultLocation = getDefaultFilterValue(LOCATION);

    if (!locationValue) {
        return defaultLocation;
    }

    if (typeof locationValue !== 'string') {
        console.error(`Invalid location format: ${locationValue}`);

        return defaultLocation;
    }

    if (!/^-?\d+\.\d+,-?\d+\.\d+$/.test(locationValue)) {
        console.error(`Invalid location format: ${locationValue}`);

        return defaultLocation;
    }

    const [lat, lng] = locationValue.split(',');

    return {
        lat: parseFloat(lat),
        lng: parseFloat(lng),
    };
};

/**
 * LOCATION
 * Builds the location object for the getVehicles API request because {lat},{lng} and {radius} are not in same query params
 * Should be in format {lat},{lng},{radius}
 *
 * @param {string} locationValue
 * @param {number} radiusValue
 *
 * @returns {string}
 */
const _buildLocationForAPI = (locationValue, radiusValue) => {
    if (!locationValue) {
        return getDefaultFilterValue(LOCATION);
    }

    // Check if locationValue is already in the full and valid format {lat},{lng},{radius}
    if (/^-?\d+\.\d+,-?\d+\.\d+,\d+$/.test(locationValue)) {
        return locationValue;
    }

    // Validate that location has format {lat},{lng}
    if (!/^-?\d+\.\d+,-?\d+\.\d+$/.test(locationValue)) {
        console.error(`Invalid location format: ${locationValue}`);

        return getDefaultFilterValue(LOCATION);
    }

    if (!radiusValue) {
        radiusValue = getDefaultFilterValue(RADIUS);
    }

    return `${locationValue},${radiusValue}`;
};

/**
 * DATES
 * Builds the dates query param
 * Should result in format {from},{to},{mode}
 *
 * @param {Object} dates - { from: string, to: string, mode: string|undefined }
 * @param {Object} query
 *
 * @returns {string}
 */
const _buildDatesQueryParam = (dates, query) => {
    const defaultDates = getDefaultFilterValue(AVAILABLE_BETWEEN);

    if (!dates) {
        return defaultDates;
    }

    if (JSON.stringify(dates) === JSON.stringify(defaultDates)) {
        return defaultDates;
    }

    if (typeof dates !== 'object') {
        console.error(`Invalid dates format: ${dates}`);

        return defaultDates;
    }

    if (dates.from) {
        const fromDate = new Date(dates.from);
        const now = new Date();

        // Set time of both dates to midnight
        fromDate.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        // Return default value if dates are in the past
        if (fromDate.getTime() <= now.getTime()) {
            return defaultDates;
        }
    }

    if (!Object.prototype.hasOwnProperty.call(dates, 'from') || !Object.prototype.hasOwnProperty.call(dates, 'to')) {
        // It can happen that only mode was sent. Check if we already have something in query, if not ignore the mode and return everything as default
        const currentDatesInQuery = getFilterValue(query, AVAILABLE_BETWEEN);

        if (currentDatesInQuery.from && currentDatesInQuery.to) {
            dates = { ...dates, from: currentDatesInQuery.from, to: currentDatesInQuery.to };
        } else {
            return defaultDates;
        }
    }

    if (!dates.mode) {
        dates.mode = dateModeValues.EXACT;
    }

    if (dates === defaultDates) {
        return dates;
    }

    return `${dates.from},${dates.to},${dates.mode}`;
};

/**
 * DATES
 * Extracts the dates object from the dates query param
 * Should be in format {from},{to},{mode}
 * Returns an object { from: string, to: string, mode: string }
 *
 * @param {string} dates
 *
 * @returns {Object}
 */
const _extractDatesFromQueryParam = dates => {
    const defaultDates = getDefaultFilterValue(AVAILABLE_BETWEEN);

    if (!dates) {
        return defaultDates;
    }

    // Expects either {from},{to},{mode} or {from},{to}
    if (typeof dates !== 'string' || !/^([^,]+),([^,]+)(,[^,]+)?$/.test(dates)) {
        console.error(`Invalid dates format: ${dates}`);

        return defaultDates;
    }

    const [from, to, mode] = dates.split(',');
    const fromDate = new Date(from);
    const now = new Date();

    // Set time of both dates to midnight
    fromDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    // Return default value if dates are in the past
    if (fromDate.getTime() <= now.getTime()) {
        return defaultDates;
    }

    return {
        from,
        to,
        mode: mode || dateModeValues.EXACT,
    };
};

/**
 * PRICES
 * Builds the prices query param
 * Should result in format {from},{to},{currency}
 *
 * @param {Array} prices - [from, to, currency]
 * @param {Object} query
 *
 * @returns {string}
 */
const _buildPriceQueryParam = (prices, query) => {
    const defaultPrices = getDefaultFilterValue(PRICE_RANGE);

    if (!prices) {
        return defaultPrices;
    }

    if (!Array.isArray(prices)) {
        console.error(`Invalid prices format: ${prices}`);

        return defaultPrices;
    }

    const formattedPrices = prices.map(item => (isNaN(item) ? item : item * 100));

    return formattedPrices.join();
};

/**
 * PRICES
 * Extracts the prices array from the prices query param
 * Should be in format {from},{to},{currency}
 * Returns an array [from, to, currency]
 *
 * @param {string} prices
 *
 * @returns {Object}
 */
const _extractPricesFromQueryParam = prices => {
    if (!prices) {
        return getDefaultFilterValue(PRICE_RANGE);
    }

    // Expects {from},{to},{currency}
    if (typeof prices !== 'string' || !/^([^,]+),([^,]+)(,[^,]+)?$/.test(prices)) {
        console.error(`Invalid prices format: ${prices}`);

        return getDefaultFilterValue(PRICE_RANGE);
    }

    const splitPrices = prices.split(',');

    return splitPrices.map(item => (isNaN(item) ? item : Number(item) / 100));
};

/**
 * SORT
 * Builds the sort value for the getVehicles API request
 * Default: If the current sort value is null, it defaults to popularity. If the location is inactive, it defaults to the country code from lang switcher & popularity
 *
 * @param {string} sortValue
 * @param {string} locationValue
 * @param {string} siteCountryCode
 *
 * @returns {string}
 */
const _buildSortForAPI = (sortValue, locationValue, siteCountryCode, isFeatured) => {
    const locationInactive = locationValue === getDefaultFilterValue(LOCATION);
    const addCountry = siteCountryCode && locationInactive;

    if (!sortValue) {
        const defaultSort = getDefaultFilterValue(SORT);

        return addCountry ? `${sortValues.COUNTRY},${defaultSort}` : defaultSort;
    }

    if (isFeatured) {
        return addCountry ? `${sortValues.COUNTRY},${sortValues.RANDOM}` : sortValues.RANDOM;
    }

    if (addCountry) {
        return sortValue === sortValues.DISTANCE
            ? `${sortValue},${sortValues.COUNTRY}`
            : `${sortValues.COUNTRY},${sortValue}`;
    }

    return sortValue;
};

/**
 * Handles the complex query params
 *
 * @param {string} key e.g. filterKeys.LOCATION
 * @param {string|Array} value
 * @param {Object} query
 *
 * @param {string} key
 */
const _buildSpecialQueryParam = (key, value, query) => {
    switch (key) {
        case LOCATION:
            return _buildLocationQueryParam(value);
        case AVAILABLE_BETWEEN:
            return _buildDatesQueryParam(value, query);
        case PRICE_RANGE:
            return _buildPriceQueryParam(value, query);
        default:
            console.error(
                `Filter key "${key}" has no custom handling. Did you mean AVAILABLE_BETWEEN instead of DATES?`
            );
    }
};

/**
 * Extracts the complex query params
 *
 * @param {string} key
 * @param {string} value
 *
 * @returns {string | Object}
 */
const _extractSpecialQueryParam = (key, value) => {
    switch (key) {
        case LOCATION:
            return _extractLocationFromQueryParam(value);
        case AVAILABLE_BETWEEN:
            return _extractDatesFromQueryParam(value);
        case PRICE_RANGE:
            return _extractPricesFromQueryParam(value);
        default:
            console.error(
                `Filter key "${key}" has no custom handling. Did you mean AVAILABLE_BETWEEN instead of DATES?`
            );
    }
};
