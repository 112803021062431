import posthog from 'posthog-js';

export default function ({ $config, app: { $meService, $cookies } }, inject) {
    const hasUserConsent = () => {
        return window.Cookiebot?.consent?.statistics;
    };

    const initPostHog = () => {
        if (posthog?.__loaded) {
            return;
        }

        const config = {
            api_host: $config.posthogHostUrl,
            person_profiles: 'identified_only',
            autocapture: true,
            persistence: hasUserConsent() ? 'localStorage' : 'memory',
            disable_session_recording: true,
            cross_subdomain_cookie: true,
            opt_out_capturing_by_default: !hasUserConsent(),
            loaded: posthog => {
                posthog.register({
                    environment: $config.appEnv,
                });
            },
        };

        posthog.init($config.posthogApiKey, config);
    };

    const identify = user => {
        if (!posthog?.__loaded || !user || (user && !user.id)) {
            return;
        }

        let country = user.country;

        if (!country) {
            country = $meService.getUserCountryBasedOnTimezone();
        }

        posthog.identify(user.id, {
            userType: user.role,
            country,
        });
    };

    const updateOptInStatus = () => {
        if (!posthog?.__loaded) {
            return;
        }

        if (hasUserConsent()) {
            if (posthog.has_opted_in_capturing() === false) {
                posthog.opt_in_capturing();
            }

            posthog.set_config({ persistence: 'localStorage' });
        } else {
            posthog.opt_out_capturing();
            posthog.set_config({ persistence: 'memory' });
        }
    };

    inject('posthog', {
        init: initPostHog,
        identify,
        updateOptInStatus,
        reset: () => posthog.reset(),
        capture: (...args) => posthog.capture(...args),
    });
}
