export default class ConversationService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    startConversationWithUser(recipientId, content, assetId = null) {
        const config = {
            method: 'post',
            url: '/conversations',
            data: {
                recipientId,
                content,
                assetId,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    sendToConversation(id, content) {
        const config = {
            method: 'post',
            url: `/conversations/${id}/messages`,
            data: {
                content,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    getMessages(id) {
        const config = {
            method: 'get',
            url: `/conversations/${id}/messages`,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
