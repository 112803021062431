// All countries that we allow
// @note Sync with allowed_country_codes array in backend's config

const allowedCountriesDetails = [
    {
        name: 'Austria',
        dialCode: '+43',
        code: 'AT',
        timezones: ['Europe/Vienna'],
        locale: 'de',
        mapCenter: { lat: 47.5162, lng: 14.5501 },
        mapZoom: 7,
    },
    {
        name: 'Australia',
        dialCode: '+61',
        code: 'AU',
        timezones: [
            'Australia/Sydney',
            'Australia/Melbourne',
            'Australia/Perth',
            'Australia/Brisbane',
            'Australia/Adelaide',
        ],
        locale: 'en',
        mapCenter: { lat: -25.2744, lng: 133.7751 },
        mapZoom: 4,
    },
    {
        name: 'Belgium',
        dialCode: '+32',
        code: 'BE',
        timezones: ['Europe/Brussels'],
        locale: 'nl',
        mapCenter: { lat: 50.5039, lng: 4.4699 },
        mapZoom: 7,
    },
    {
        name: 'Canada',
        dialCode: '+1',
        code: 'CA',
        timezones: ['America/Toronto', 'America/Vancouver', 'America/Edmonton', 'America/Halifax', 'America/St_Johns'],
        locale: 'en',
        mapCenter: { lat: 56.1304, lng: -106.3468 },
        mapZoom: 4,
    },
    {
        name: 'Denmark',
        dialCode: '+45',
        code: 'DK',
        timezones: ['Europe/Copenhagen'],
        locale: 'dk',
        mapCenter: { lat: 55.4968389623689, lng: 9.474533545291404 },
        mapZoom: 7,
    },
    {
        name: 'Finland',
        dialCode: '+358',
        code: 'FI',
        timezones: ['Europe/Helsinki'],
        locale: 'fi',
        mapCenter: { lat: 65.192059, lng: 24.945831 },
        mapZoom: 5,
    },
    {
        name: 'France',
        dialCode: '+33',
        code: 'FR',
        timezones: ['Europe/Paris'],
        locale: 'fr',
        mapCenter: { lat: 46.227638, lng: 2.213749 },
        mapZoom: 5,
    },
    {
        name: 'Germany',
        dialCode: '+49',
        code: 'DE',
        timezones: ['Europe/Berlin', 'Europe/Busingen'],
        locale: 'de',
        mapCenter: { lat: 51.1657, lng: 10.4515 },
        mapZoom: 6,
    },
    {
        name: 'Greece',
        dialCode: '+30',
        code: 'GR',
        timezones: ['Europe/Athens'],
        locale: 'el',
        mapCenter: { lat: 39.074208, lng: 21.824312 },
        mapZoom: 5,
    },
    {
        name: 'Iceland',
        dialCode: '+354',
        code: 'IS',
        timezones: ['Atlantic/Reykjavik'],
        locale: 'is',
        mapCenter: { lat: 64.963051, lng: -19.020835 },
        mapZoom: 5,
    },
    {
        name: 'Ireland',
        dialCode: '+353',
        code: 'IE',
        timezones: ['Europe/Dublin'],
        locale: 'en',
        mapCenter: { lat: 53.41291, lng: -8.24389 },
        mapZoom: 5,
    },
    {
        name: 'Italy',
        dialCode: '+39',
        code: 'IT',
        timezones: ['Europe/Rome'],
        locale: 'it',
        mapCenter: { lat: 41.87194, lng: 12.56738 },
        mapZoom: 5,
    },
    {
        name: 'Liechtenstein',
        dialCode: '+423',
        code: 'LI',
        timezones: ['Europe/Vaduz'],
        locale: 'de',
        mapCenter: { lat: 47.166, lng: 9.555373 },
        mapZoom: 7,
    },
    {
        name: 'Luxembourg',
        dialCode: '+352',
        code: 'LU',
        timezones: ['Europe/Luxembourg'],
        locale: 'de',
        mapCenter: { lat: 49.815273, lng: 6.129583 },
        mapZoom: 7,
    },
    {
        name: 'Malta',
        dialCode: '+356',
        code: 'MT',
        timezones: ['Europe/Malta'],
        locale: 'en',
        mapCenter: { lat: 35.937496, lng: 14.375416 },
        mapZoom: 7,
    },
    {
        name: 'Netherlands',
        dialCode: '+31',
        code: 'NL',
        timezones: ['Europe/Amsterdam'],
        locale: 'nl',
        mapCenter: { lat: 52.132633, lng: 5.291266 },
        mapZoom: 7,
    },
    {
        name: 'New Zealand',
        dialCode: '+64',
        code: 'NZ',
        timezones: ['Pacific/Auckland', 'Pacific/Chatham'],
        locale: 'en',
        mapCenter: { lat: -40.9006, lng: 174.886 },
        mapZoom: 6,
    },
    {
        name: 'Norway',
        dialCode: '+47',
        code: 'NO',
        timezones: ['Europe/Oslo'],
        locale: 'no',
        mapCenter: { lat: 64.329, lng: 10.501 },
        mapZoom: 5,
    },
    {
        name: 'Portugal',
        dialCode: '+351',
        code: 'PT',
        timezones: ['Europe/Lisbon', 'Atlantic/Azores', 'Atlantic/Madeira'],
        locale: 'pt',
        mapCenter: { lat: 39.399872, lng: -8.224454 },
        mapZoom: 5,
    },
    {
        name: 'Spain',
        dialCode: '+34',
        code: 'ES',
        timezones: ['Europe/Madrid', 'Atlantic/Canary'],
        locale: 'es',
        mapCenter: { lat: 40.46366700000001, lng: -3.74922 },
        mapZoom: 5,
    },
    {
        name: 'Sweden',
        dialCode: '+46',
        code: 'SE',
        timezones: ['Europe/Stockholm'],
        locale: 'se',
        mapCenter: { lat: 62.329, lng: 15.101 },
        mapZoom: 5,
    },
    {
        name: 'Switzerland',
        dialCode: '+41',
        code: 'CH',
        timezones: ['Europe/Zurich'],
        locale: 'de',
        mapCenter: { lat: 46.8182, lng: 8.2275 },
        mapZoom: 7,
    },
    {
        name: 'United Kingdom',
        dialCode: '+44',
        code: 'UK',
        timezones: ['GB', 'Europe/London', 'Europe/Belfast', 'GB-Eire'],
        locale: 'en',
        mapCenter: { lat: 55.378051, lng: -3.435973 },
        mapZoom: 5,
    },
    {
        name: 'United States',
        dialCode: '+1',
        code: 'US',
        timezones: [
            'America/New_York',
            'America/Chicago',
            'America/Denver',
            'America/Los_Angeles',
            'America/Anchorage',
            'Pacific/Honolulu',
        ],
        locale: 'en',
        mapCenter: { lat: 37.0902, lng: -95.7129 },
        mapZoom: 4,
    },
];

export function getAllowedCountryCodes() {
    return allowedCountriesDetails.map(country => {
        return country.code;
    });
}

export default allowedCountriesDetails;
