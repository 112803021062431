<template>
    <div class="vehicle-image-carousel" :class="{ 'vehicle-image-carousel--fullscreen': fullscreen }">
        <div v-if="fullscreen" class="vehicle-image-carousel__header">
            <button class="button button--secondary-contrast button--small" @click.prevent="$emit('toggle-carousel')">
                <svg-icon name="close" />
            </button>
        </div>

        <div :id="uid" ref="carouselContainer" class="vehicle-image-carousel__slides">
            <div
                v-for="(image, index) in imageAttributes"
                :key="image.id"
                class="vehicle-image-carousel__slide"
                :class="{ 'vehicle-image-carousel__slide--clickable': openFullImageOnClick }"
                @click="handleSlideClick($event, index, image)"
            >
                <picture>
                    <template v-if="image.srcset">
                        <source
                            v-if="image.webpSrcset"
                            type="image/webp"
                            :alt="image.alt"
                            :srcset="image.webpSrcset"
                            :sizes="srcsetSizes"
                        />
                        <source type="image/jpeg" :alt="image.alt" :srcset="image.srcset" :sizes="srcsetSizes" />
                        <img
                            :src="image.src"
                            :srcset="image.srcset"
                            :alt="image.alt"
                            :sizes="srcsetSizes"
                            :loading="index === 0 ? 'eager' : 'lazy'"
                            @load="onImageLoad(index, $event)"
                            @error="onImageError(index, $event)"
                        />
                    </template>
                    <template v-else>
                        <source v-if="image.webpSrc" type="image/webp" :srcset="image.webpSrc" :sizes="srcsetSizes" />
                        <source type="image/jpeg" :srcset="image.src" :sizes="srcsetSizes" />
                        <img
                            :src="image.src"
                            :alt="image.alt"
                            :sizes="srcsetSizes"
                            :loading="index === 0 ? 'eager' : 'lazy'"
                            @load="onImageLoad(index, $event)"
                            @error="onImageError(index, $event)"
                        />
                    </template>
                </picture>
            </div>
        </div>

        <PaginationDots
            v-if="showPagination"
            :total-dots="totalImages"
            :active-dot="currentSlide"
            @go-to-slide="goToSlide"
        />

        <template v-if="isLargeScreen">
            <div class="vehicle-image-carousel__prev" @click.prevent="prev">
                <svg-icon name="keyboard-arrow-left" />
            </div>
            <div class="vehicle-image-carousel__next" @click.prevent="next">
                <svg-icon name="keyboard-arrow-right" />
            </div>
        </template>

        <div v-if="fullscreen" class="vehicle-image-carousel__footer">
            <div class="vehicle-image-carousel__progress">
                {{ carouselProgress }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaginationDots from '~/components/PaginationDots.vue';

export default {
    components: {
        PaginationDots,
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
        // One of the sizes that are available for vehicle images
        imageSize: {
            type: String,
            default: 'full',
        },
        imageCount: {
            type: Number,
            default: -1,
        },
        srcsetSizes: {
            type: String,
            default: '100vw',
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        useKeyboard: {
            type: Boolean,
            default: false,
        },
        indexOfClickedImage: {
            type: Number,
            default: 0,
        },
        lazyLoadNearViewport: {
            type: Boolean,
            default: false,
        },
        isNearViewportFold: {
            type: Boolean,
            default: false,
        },
        preloadFirstImage: {
            type: Boolean,
            default: false,
        },
        openFullImageOnClick: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            siema: null,
            loadedImages: new Set(),
            failedImages: new Set(),
            currentSlide: this.indexOfClickedImage,
            siemaInitialized: false,
            allImagesLoaded: false,
        };
    },
    computed: {
        ...mapGetters(['isLargeScreen']),
        uid() {
            return `carousel-${Math.random().toString(36).slice(2, 7)}`;
        },
        showPagination() {
            if (this.lazyLoadNearViewport && !this.isNearViewportFold) {
                return false;
            }

            if (this.fullscreen) {
                return false;
            }

            return true;
        },
        imageAttributes() {
            if (this.lazyLoadNearViewport && !this.isNearViewportFold) {
                return [];
            }

            let images = [...this.images];

            if (this.imageCount > -1) {
                images = images.slice(0, this.imageCount);
            }

            return images.map(image => {
                if (!image.sizes && image.src && image.alt) {
                    return image;
                }

                let src = image.sizes[this.imageSize];
                const alt = this.$t('vehicle_images.common_alt');

                if (!src) {
                    // Fallback to full if the given size doesn't exist for this image
                    src = image.sizes.full;
                }

                if (this.imageSize !== 'full') {
                    const formatted = { src, alt };

                    if (image.webpSizes[this.imageSize]) {
                        formatted.webpSrc = image.webpSizes[this.imageSize];
                    }

                    return formatted;
                }

                const srcset = [];
                const webpSrcset = [];

                // Note: If changes are made here, also update the linkPreload imagesrcet attribute in Vehicle.vue
                Object.keys(image.sizes).forEach(key => {
                    switch (key) {
                        case 'card':
                            srcset.push(`${image.sizes[key]} 400w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 400w`);
                            }

                            break;
                        case 'medium':
                            srcset.push(`${image.sizes[key]} 800w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 800w`);
                            }

                            break;
                        case 'large':
                            srcset.push(`${image.sizes[key]} 1100w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 1100w`);
                            }

                            break;
                        case 'xlarge':
                            srcset.push(`${image.sizes[key]} 1280w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 1280w`);
                            }

                            break;
                        case 'full':
                        default:
                            srcset.push(`${image.sizes[key]} 1600w`);

                            if (image.webpSizes[this.imageSize]) {
                                webpSrcset.push(`${image.webpSizes[key]} 1600w`);
                            }

                            break;
                    }
                });

                const formatted = { src: image.sizes.card, alt, srcset: srcset.join(', ') };

                if (webpSrcset.length > 0) {
                    formatted.webpSrcset = webpSrcset.join(', ');
                }

                return formatted;
            });
        },
        totalImages() {
            return this.imageAttributes.length;
        },
        carouselProgress() {
            return `${this.currentSlide + 1} / ${this.totalImages}`;
        },
    },
    async mounted() {
        if (this.fullscreen) {
            const bodyScrollLock = await import('body-scroll-lock');
            this.disableBodyScroll = bodyScrollLock.disableBodyScroll;
            this.clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;

            this.disableBodyScroll(document.getElementById(this.uid));
        }

        if (window.innerWidth > 991 && this.useKeyboard) {
            window.addEventListener('keyup', this.changeSlideWithKeys, { passive: true });
        }
    },
    beforeDestroy() {
        if (this.fullscreen) {
            this.clearAllBodyScrollLocks();
        }

        if (this.siema) {
            this.siema.destroy();
        }

        if (window.innerWidth > 991 && this.useKeyboard) {
            window.removeEventListener('keyup', this.changeSlideWithKeys);
        }
    },
    methods: {
        onImageLoad(index) {
            this.loadedImages.add(index);
            this.initSiema();

            if (this.loadedImages.size + this.failedImages.size === this.imageAttributes.length) {
                this.allImagesLoaded = true;
                this.updateSiema();
            }
        },
        onImageError(index) {
            this.failedImages.add(index);
            this.initSiema();

            if (this.loadedImages.size + this.failedImages.size === this.imageAttributes.length) {
                this.allImagesLoaded = true;
                this.updateSiema();
            }
        },
        initSiema() {
            if (!process.client || this.siemaInitialized) {
                return;
            }

            if (!this.loadedImages.has(0) && !this.failedImages.has(0)) {
                return;
            }

            const container = this.$refs.carouselContainer;

            if (!container || container.children.length === 0) {
                return;
            }

            try {
                this.siema = new this.$Siema({
                    selector: `#${this.uid}`,
                    duration: 200,
                    easing: 'ease-out',
                    perPage: 1,
                    startIndex: this.indexOfClickedImage,
                    draggable: !this.isLargeScreen,
                    multipleDrag: false,
                    loop: true,
                    onInit: () => {
                        this.$nextTick(() => {
                            this.applyClassToSiema();
                        });
                    },
                    onChange: () => {
                        this.onSlideChange();
                    },
                });
                this.siemaInitialized = true;
            } catch (error) {
                this.$sentry.captureException(error);
            }
        },
        updateSiema() {
            if (this.siema && this.allImagesLoaded) {
                this.$nextTick(() => {
                    this.siema.resizeHandler();
                    this.applyClassToSiema();
                });
            }
        },
        applyClassToSiema() {
            const container = this.$refs.carouselContainer;

            if (container) {
                container.classList.add('siema-initialized');
            }
        },
        onSlideChange() {
            if (this.siema) {
                this.currentSlide = this.siema.currentSlide;
            }
        },
        prev(event) {
            if (event) {
                event.stopPropagation();
            }

            if (this.siema) {
                this.siema.prev();
            }
        },
        next(event) {
            if (event) {
                event.stopPropagation();
            }

            if (this.siema) {
                this.siema.next();
            }
        },
        goToSlide(event, index) {
            if (event) {
                event.stopPropagation();
            }

            if (this.siema && this.isLargeScreen) {
                this.siema.goTo(index);
            }
        },
        changeSlideWithKeys(e) {
            // Left arrow key (37); Right arrow key (39)
            if (e.keyCode === 37) {
                this.prev();
            }

            if (e.keyCode === 39) {
                this.next();
            }
        },
        handleSlideClick(event, index, image) {
            if (event.pointerType === 'mouse' && this.siema?.currentSlide !== index) {
                event.stopPropagation();
                event.preventDefault();

                return;
            }

            if (this.openFullImageOnClick && image.srcFull) {
                window.open(image.srcFull, '_blank');
            }

            this.$emit('slide-click', index);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

$header-height: 94px;
$footer-height: 72px;

.vehicle-image-carousel {
    position: relative;
    height: 100%;
    overflow: hidden;
    contain: content;

    &__slides {
        height: 100%;
        overflow: hidden;
    }

    &__slide {
        height: 100%;

        img {
            height: 100% !important;
            object-fit: cover;
            object-position: center;
            margin: auto;
            width: 100%;
        }

        &--clickable {
            cursor: pointer;
        }
    }

    &__prev,
    &__next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        z-index: 10;
        opacity: 0;
        transition: opacity 0.2s ease, transform 0.2s ease;
        background: $white;

        .svg-icon {
            width: 24px;
            height: 24px;
        }

        &:hover {
            transform: translateY(-50%) scale(1.05);
        }
    }

    &__prev {
        left: 0;
        margin-left: 16px;
    }

    &__next {
        right: 0;
        margin-right: 16px;
    }

    &__prev,
    &__next {
        .svg-icon {
            cursor: pointer;
        }
    }

    &:hover {
        .vehicle-image-carousel__prev,
        .vehicle-image-carousel__next {
            opacity: 1;
        }
    }

    &--fullscreen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-modal + 1;
        background: white;

        .vehicle-image-carousel {
            &__header {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 2rem 1rem 1rem;

                button {
                    .svg-icon {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            &__footer {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $white;
                padding: 1rem 1rem 2rem;
            }

            &__slides {
                height: calc(100% - ($header-height + $footer-height));
            }

            &__slide {
                img {
                    object-fit: contain;
                    margin: auto;

                    @include lg {
                        max-width: 75%;
                    }
                }
            }

            &__prev,
            &__next {
                height: fit-content;
                top: 50%;
                opacity: 1;
                background: none;
                width: 15%;

                .svg-icon {
                    width: 40px;
                    height: 40px;
                    color: $darkest-gray;
                    cursor: pointer;
                }
            }

            // landscape mobile
            @media screen and (max-height: $bp-sm-min) and (orientation: landscape) {
                &__header {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 2;
                    background: none;
                }

                &__slides {
                    height: 100%;
                }

                &__footer {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 2;
                    background: none;
                }

                &__header button,
                &__progress {
                    background: rgba(255, 255, 255, 0.5);
                    backdrop-filter: blur(18px);
                    border-radius: $default-border-radius;
                }

                &__progress {
                    padding: 1rem;
                }

                &__slides {
                    height: 100%;
                    top: 0;
                    background-color: $white;
                    img,
                    source {
                        width: 100vw;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss">
.siema-initialized {
    height: 100%;

    & > div {
        height: 100%;

        & > div {
            height: 100%;
        }
    }
}
</style>
