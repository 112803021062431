import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['isLoggedIn', 'user']),
    },
    methods: {
        initializePosthog() {
            this.$posthog.init();
        },
    },
    mounted() {
        window.addEventListener('CookiebotOnLoad', this.initializePosthog);
        window.addEventListener('CookiebotOnAccept', this.$posthog.updateOptInStatus);
        window.addEventListener('CookiebotOnDecline', this.$posthog.updateOptInStatus);
    },
    beforeDestroy() {
        window.removeEventListener('CookiebotOnLoad', this.initializePosthog);
        window.removeEventListener('CookiebotOnAccept', this.$posthog.updateOptInStatus);
        window.removeEventListener('CookiebotOnDecline', this.$posthog.updateOptInStatus);
    },
    watch: {
        isLoggedIn(newValue) {
            if (newValue) {
                this.$posthog.identify({
                    id: this.user.id,
                    role: this.user.role,
                    country: this.user.country,
                });
            } else {
                this.$posthog.reset();
            }
        },
    },
};
