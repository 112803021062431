<template>
    <div class="pagination-dots">
        <button
            v-for="(dot, index) in totalDots"
            :key="index"
            :class="getDotClasses(index)"
            :style="{ left: `${paginationOffset}px` }"
            @click.prevent="$emit('go-to-slide', $event, index)"
        />
    </div>
</template>

<script>
export default {
    props: {
        totalDots: {
            type: Number,
            required: true,
        },
        activeDot: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            paginationOffset: 0,
        };
    },
    watch: {
        activeDot() {
            this.updatePaginationOffset();
        },
    },
    mounted() {
        if (this.activeDot > 0) {
            this.updatePaginationOffset();
        }
    },
    methods: {
        updatePaginationOffset() {
            const totalDots = this.totalDots;
            const currentIndex = this.activeDot;

            if (totalDots <= 5) {
                return;
            }

            if (currentIndex > 2) {
                const dotsHiddenOnLeft = Math.min(Math.max(currentIndex - 2, 0), totalDots - 5);
                this.paginationOffset = -16 * dotsHiddenOnLeft;
            } else {
                this.paginationOffset = 0;
            }
        },
        getDotClasses(index) {
            const diff = Math.abs(index - this.activeDot);

            return [
                'pagination-dots__dot',
                {
                    'pagination-dots__dot--active': index === this.activeDot,
                    'pagination-dots__dot--adjacent': diff === 1,
                    'pagination-dots__dot--distant': diff > 1,
                },
            ];
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

.pagination-dots {
    position: absolute;
    bottom: 15px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    max-width: 80px;
    overflow: hidden;
    transition: all 0.2s;

    &__dot {
        position: relative;
        display: inline-block;
        opacity: 0.4;
        background: $white;
        border: none;
        border-radius: 100%;
        padding: 0;
        min-width: 8px;
        min-height: 8px;
        margin: 4px;
        vertical-align: middle;
        transition: all 0.2s;
        cursor: pointer;

        &--active {
            opacity: 1;
            transform: scale(1);
        }

        &--adjacent {
            transform: scale(0.8);
        }

        &--distant {
            transform: scale(0.6);
        }
    }
}
</style>
