// Categories for extra charges
// @note Keep this in sync with the model's constants in the backend (see ExtraCharge.php)
export const DAMAGE = 'damage';
export const EQUIPMENT = 'equipment';
export const ADDON = 'addon';
export const EXTRA_DISTANCE = 'extra_distance';
export const ROAD_TOLL = 'road_toll';
export const FUEL = 'fuel';
export const OTHER = 'other';

export const automaticCategories = [DAMAGE, EXTRA_DISTANCE];
