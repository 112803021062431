import { ASSET_TYPE_VEHICLE } from '~/config/assetTypes';

import { prepareQueryForApiRequest } from '~/helpers/queryParser';

/**
 * Number of vehicles to display per page
 */
export const VEHICLES_PER_PAGE = 24;

export default class VehicleService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    getVehicleData(country, licensePlate) {
        const config = {
            method: 'get',
            url: `/vehicle-data/${country}/${licensePlate}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates a new vehicle with given vehicleType for logged in user
     */
    createVehicle(incomingData = {}) {
        const data = { ...incomingData };

        data.type = ASSET_TYPE_VEHICLE;

        if (!data.subType && data.vehicleType) {
            data.subType = data.vehicleType;
        }

        const config = {
            method: 'post',
            url: `/assets`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to store vehicle details
     * @param {integer} id ID of the vehicle
     * @param {object} data
     */
    updateVehicle(id, data) {
        const config = {
            method: 'put',
            url: `/assets/${id}`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to store aux data for a vehicle
     * @param {integer} id ID of the vehicle
     * @param {string} key
     * @param {mixed} value
     */
    setAuxData(id, key, value) {
        const config = {
            method: 'post',
            url: `/assets/${id}/set-aux-data`,
            data: { key, value },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to delete given vehicle
     * @param {integer} id ID of the vehicle
     */
    deleteVehicle(id) {
        const config = {
            method: 'delete',
            url: `/assets/${id}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets a list of vehicles based on the given filters, sorting and page
     *
     * @param {object} query Filters to apply
     * @param {string|null} siteCountryCode Country code of the site (needed for sorting)
     * @param {integer|null} page Page to fetch
     * @param {string|array|null} include Relation(s) to include
     * @param {integer|null} pageSize Default: @see VEHICLES_PER_PAGE
     * @param target
     */
    getVehicles(
        query,
        siteCountryCode = null,
        page = null,
        pageSize = VEHICLES_PER_PAGE,
        include = null,
        target = 'list'
    ) {
        const config = {
            method: 'get',
            url: '/assets',
            params: {},
        };

        config.params = prepareQueryForApiRequest(query, siteCountryCode);

        config.params.page = {
            size: pageSize,
        };

        if (page !== null) {
            config.params.page.number = page;
        }

        if (include !== null) {
            let toInclude = include;

            if (Array.isArray(include)) {
                toInclude = include.join(',');
            }

            config.params.include = toInclude;
        }

        config.params.target = target;

        return this.$axios(config).then(rawResponse => {
            const response = rawResponse.data;
            let vehicles = response.data;

            if (response.matchedDates) {
                // This is a response for a flexible dates search
                // We'll populate the matchedDates value on each vehicle
                vehicles = vehicles.map(vehicle => {
                    const updated = { ...vehicle };

                    updated.matchedDates = response.matchedDates[vehicle.id];

                    return updated;
                });
            }

            return {
                vehicles,
                pagination: {
                    totalPages: response.meta.last_page,
                    currentPage: response.meta.current_page,
                    subTypeTotal: response.meta.sub_type_total,
                },
                total: response.meta.total,
            };
        });
    }

    /**
     * Gets a vehicles count based on the given filters and sorting
     *
     * @param {object} query Filters to apply
     * @param {string|null} siteCountryCode Country code of the site (needed for sorting)
     */
    getVehiclesCount(query, siteCountryCode) {
        const config = {
            method: 'get',
            url: '/assets',
            params: {},
        };

        config.params = prepareQueryForApiRequest(query, siteCountryCode);
        config.params.target = 'count';

        return this.$axios(config).then(rawResponse => {
            const response = rawResponse.data;
            const data = response.data;

            return { count: data.count, roundedCount: data.rounded_count };
        });
    }

    /**
     * Gets the available asset filters
     */
    getAvailableFilters() {
        const config = {
            method: 'get',
            url: '/asset-filters',
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get a single vehicle
     *
     * Appends pricingDates as params if both from and to are given
     *
     * @param {integer} id
     * @param {string|null} fromDate From date to calculate pricing for
     * @param {string|null} toDate To date to calculate pricing for
     */
    getVehicle(id, fromDate = null, toDate = null) {
        const config = {
            method: 'get',
            url: `/assets/${id}`,
        };

        if (!id) {
            return;
        }

        if (fromDate && toDate) {
            config.params = {
                fromDate,
                toDate,
            };
        }

        return this.$axios(config).then(rawResponse => {
            const response = rawResponse.data;
            const vehicle = response.data;

            if (response.locationPage) {
                vehicle.locationPage = response.locationPage;
            }

            return vehicle;
        });
    }

    /**
     * Get current user's vehicles
     */
    getMyVehicles() {
        const config = {
            method: 'get',
            url: '/me/assets',
            params: {
                filter: {
                    type: 'vehicle',
                },
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get a vehicle's reviews
     *
     * @param {integer} id ID of the vehicle to fetch reviews for
     */
    getReviews(id) {
        const config = {
            method: 'get',
            url: `/assets/${id}/reviews`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get a vehicle's unavailable dates
     *
     * @param {integer} id ID of the vehicle
     * @param {integer|null} excludeBookingId ID of the booking to exclude from the results
     * @param {boolean} ignoreLeadTime Ignore lead time when checking for availability
     */
    getUnavailableDates(id, excludeBookingId = null, ignoreLeadTime = false) {
        const config = {
            method: 'get',
            url: `/assets/${id}/unavailable-dates`,
        };

        if (excludeBookingId) {
            config.params = { 'except-booking': excludeBookingId };
        }

        if (ignoreLeadTime) {
            config.params = { ...config.params, 'ignore-lead-time': ignoreLeadTime };
        }

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get a vehicle's related vehicles
     *
     * @param {integer} id ID of the vehicle to fetch related for
     * @param {object|null} filters Filter of vehicles to get
     * @param {integer|null} count Number of vehicles to get
     */
    getRelated(id, filters = null, count = null) {
        const config = {
            method: 'get',
            url: `/assets/${id}/related`,
            params: {},
        };

        if (count) {
            config.params.count = count;
        }

        if (filters !== null) {
            config.params.filter = { ...filters };
        }

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to confirm insurance terms for vehicle
     * @param {integer} id ID of the vehicle
     */
    confirmInsuranceRequirements(id) {
        const config = {
            method: 'post',
            url: `/assets/${id}/confirm-insurance-requirements`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get the price levels that's applicable for the vehicle's type
     * @param {integer} id ID of the vehicle
     */
    getInsurancePriceLevels(id) {
        const config = {
            method: 'get',
            url: `/assets/${id}/insurance-price-levels`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get the available insurance products for given vehicle
     * @param {integer} id ID of the vehicle
     */
    getAvailableInsuranceProducts(id) {
        const config = {
            method: 'get',
            url: `/assets/${id}/available-insurance-products`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets the vehicle's rented periods
     * @param {integer} id ID of the vehicle
     */
    getRentedPeriods(id) {
        const config = {
            method: 'get',
            url: `/assets/${id}/rented-periods`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets the vehicle's unavailable periods
     * @param {integer} id ID of the vehicle
     */
    getUnavailablePeriods(id) {
        const config = {
            method: 'get',
            url: `/assets/${id}/unavailable-periods`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates a new unavailable period for a vehicle
     *
     * @param {integer} assetId ID of the vehicle
     * @param {string} fromDate Start date of the unavailable period
     * @param {string} toDate End date of the unavailable period
     * @param {string} note Any note or comment
     */
    createUnavailablePeriod(assetId, fromDate, toDate, note) {
        const config = {
            method: 'post',
            url: `/unavailable-periods`,
            data: {
                assetId,
                fromDate,
                toDate,
                note,
            },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Updates a unavailable period for a vehicle
     *
     * @param assetId
     * @param fromDate
     * @param toDate
     * @param note
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateUnavailablePeriod(id, data) {
        const config = {
            method: 'put',
            url: `/unavailable-periods/${id}`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes an unavailable period
     *
     * @param {integer} id ID of the unavailable period to remove
     */
    deleteUnavailablePeriod(id) {
        const config = {
            method: 'delete',
            url: `/unavailable-periods/${id}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get vehicle images
     */
    getImages(id) {
        const config = {
            method: 'get',
            url: `/assets/${id}/images`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees left (counter-clockwise)
     *
     * @param {integer} id ID of the image to modify
     */
    rotateImageLeft(id) {
        const config = {
            method: 'post',
            url: `/asset-images/${id}/rotate-left`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees right (clockwise)
     *
     * @param {integer} id ID of the image to modify
     */
    rotateImageRight(id) {
        const config = {
            method: 'post',
            url: `/asset-images/${id}/rotate-right`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Moves an images 1 step up
     *
     * @param {integer} id ID of the image to modify
     */
    moveUp(id) {
        const config = {
            method: 'post',
            url: `/asset-images/${id}/move-up`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Moves an images 1 step down
     *
     * @param {integer} id ID of the image to modify
     */
    moveDown(id) {
        const config = {
            method: 'post',
            url: `/asset-images/${id}/move-down`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes an image
     *
     * @param {integer} id ID of the image to remove
     */
    deleteImage(id) {
        const config = {
            method: 'delete',
            url: `/asset-images/${id}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    setRegistrationDocument(id, { key, value }) {
        const formData = new FormData();
        formData.append(key, value);

        const config = {
            method: 'post',
            url: `/assets/${id}/registration-document`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    deleteRegistrationDocument(id) {
        const config = {
            method: 'delete',
            url: `/assets/${id}/registration-document`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets the available addons for given vehicle
     *
     * @param {integer} id
     * @param {string} fromDate
     * @param {string} toDate
     */
    getAvailableAddons(id, fromDate = null, toDate = null, filters = null) {
        const config = {
            method: 'get',
            url: `/assets/${id}/available-addons`,
            params: {},
        };

        if (fromDate && toDate) {
            config.params = {
                fromDate,
                toDate,
            };
        }

        if (filters !== null) {
            config.params.filter = { ...filters };
        }

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Gets the pricing examples for given vehicle
     *
     * @param {integer} id
     * @param {object} pricingInfo Pricing info to use for calculation (season, pricePerNight, increasePercentage, discountPercentage, cleaningFee)
     */
    getPricingExamples(id, pricingInfo) {
        const config = {
            method: 'get',
            url: `/assets/${id}/pricing-examples`,
            params: { ...pricingInfo },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Get owner addons
     */
    getOwnerAddons(id) {
        const config = {
            method: 'get',
            url: `/assets/${id}/owner-addons`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Make a request to store owner addon details
     * @param {integer} id ID of the vehicle
     * @param {integer} addonId ID of the owner addon
     * @param {object} data
     */
    updateOwnerAddon(id, addonId, data) {
        const config = {
            method: 'put',
            url: `/assets/${id}/owner-addons/${addonId}`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates new owner addon for a vehicle
     * @param {integer} id ID of the vehicle
     * @param {object} data
     */
    createOwnerAddon(id, data) {
        const config = {
            method: 'post',
            url: `/assets/${id}/owner-addons`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates new owner addon for a vehicle
     * @param {integer} id ID of the vehicle
     * @param {integer} addonId ID of the owner addon
     */
    deleteOwnerAddon(id, addonId) {
        const config = {
            method: 'delete',
            url: `/assets/${id}/owner-addons/${addonId}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
