/**
 * Credit report results
 *
 * @note Keep this in sync with the result constants in the backend (see CreditReport.php)
 */
export const RESULT_OK = 0;
export const RESULT_REJECTED_REMARKS = 1;
export const RESULT_REJECTED_OTHER = 2;
export const RESULT_ERROR = -1;
export const RESULT_BLOCKED = -2;
export const RESULT_NOT_FOUND = -3;
